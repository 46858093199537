<template>
  <div>
    <div class="header-login wf-section">
      <div class="container-header-login w-container">
        <div class="w-layout-grid grid-header-login">
          <div id="w-node-_03ddceca-a534-b55e-e4cc-69b8261c6240-261c623d" class="zone-logo"><img :src="require('@/assets/images/logo_pautions.svg')" loading="lazy" width="120" alt="" /></div>
          <div id="w-node-_584d43b4-e90f-3956-cfd0-aa02149a422e-261c623d" class="zone-call">
            <a href="tel:+33970386826" class="link-block w-inline-block"><img :src="require('@/assets/images/ic_tel.svg')" loading="lazy" width="20" alt="" /></a>
            <p id="w-node-_03ddceca-a534-b55e-e4cc-69b8261c6243-261c623d" class="paragraph info">Besoin d’aide pour vous connecter ? Appelez notre service client au 09 70 38 68 26.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="zone-login wf-section">
      <div class="container-login w-container">
        <div class="w-layout-grid grid-login">
          <div id="w-node-_9cff9bef-e8f5-03b3-5b12-fb9ebf49daa0-65a4bdd8" class="div-login">
            <img :src="require('@/assets/images/logo_pautions.svg')" loading="lazy" width="150" alt="Pautions logo" class="image" />
            <p class="paragraph-4">accédez à votre compte</p>
            <div class="login-form w-form">
              <form class="form">
                <input type="text" class="champ-login w-input" maxlength="256" placeholder="mot de passe" v-model="password" />
                <input type="text" class="champ-login w-input" maxlength="256" placeholder="mot de passe" v-model="password1" />
                <a @click="update()" type="submit" class="submit-button w-button">Modifier</a>
              </form>
            </div>
            <p class="mot-de-passe-oublie" style="visibility: hidden;">se connecter ?</p>
          </div>
          <div id="w-node-ea80a0a7-848b-0674-dc23-00e09a5e212c-65a4bdd8" class="div-img-login">
            <img :src="require('@/assets/images/man_jump_crm_login_light_2.jpg')" loading="lazy" alt="" height="600" sizes="(max-width: 694px) 90vw, (max-width: 991px) 625px, 60vw" class="image-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Login",
  data(){
    return {
      password:null,
      password1:null
    }
  },
  methods: {
    update() {
      if(this.password1 != this.password){
        this.$toastr.e("Les mots de passe ne sont pas identique !");
        return false;
      }
      var params = {
        password: this.password,
        uuid: this.$route.params.uuid
      }
      this.$store.api.ajax('/users/password', params, res => {
        if(res.status === true){
          this.$router.push({path:'/login'})
        }
      })
    },
  },
  components: {
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-4";
  },
};
</script>
